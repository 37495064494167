.ant-tabs.no-margin .ant-tabs-nav {
    margin-bottom: 0;
}

.no-padding tbody .ant-table-cell {
    padding: 0 !important;
}



.loading-container {
    height: 64px;
    width: 64px;
    top: calc(100vh / 2 - 32px);
    left: calc(100vw / 2 - 32px);
    perspective: 210px;
    position: absolute;
  }
  
  .loading-flipper {
    height: 100%;
    width: 100%;
    animation: flip 2.4s linear infinite;
    background: #0086fe;
  }
  
  @keyframes flip {
    0% {
      transform: rotateX(0deg) rotateY(0deg);
    }
    25% {
      transform: rotateX(180deg) rotateY(0deg);
    }
    50% {
      transform: rotateX(180deg) rotateY(180deg);
    }
    75% {
      transform: rotateX(0deg) rotateY(180deg);
    }
    100% {
      transform: rotateX(0deg) rotateY(360deg);
    }
  }
  