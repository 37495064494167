/* @tailwind base;
@tailwind components; */
@tailwind utilities;

body {
  margin: 0;
  font-family: "PingFang SC", "Microsoft YaHei", 宋体, sans-serif;
  -webkit-font-smoothing: antialiased;
  font-weight: 400;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
* {
  box-sizing: border-box;
}
/* common */
.relative {
  position: relative;
}
.absolute {
  position: absolute;
}
.flex {
  display: flex;
}
.column {
  flex-direction: column;
}

input {
  color: rgb(99, 96, 96);
}

* {
  box-sizing: border-box;
  user-select: none;
}

.bg-primary {
  background-color: orange;
  color: #fff;
}

.color-primary {
  color: orange;
}
.color-lightgray {
  color: lightgray;
}
.color-gray {
  color: gray;
}


.size-sm {
  font-size: 12px;
}

a {
  text-decoration: none;
  outline: none;
}
input, button {
  outline: none;
}

hr {
  color: #aaa ;
}



.flex {
  display: flex;
}
.inline-block {
  display: inline-block;
}
.flex.center,
.f-center {
  justify-content: center;
  align-items: center;
}
.a-center {
  align-items: center;
}
.j-center {
  justify-content: center;
}
.space-between {
  justify-content: space-between;
}
.a-flex-start {
  align-items: flex-start;
}
.text-align-center {
  text-align: center;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

.f-column {
  flex-direction: column;
}

.no-drag {
  -webkit-app-region: no-drag;
}

.flex1 {
  flex: 1;
}

.none {
  display: none !important;
}

.bold {
  font-weight: bold;
}


[class="12px"] {
  font-size: 12px;
}

[class="10px"] {
  font-size: 10px;
}

.no-scroll-bar::-webkit-scrollbar {
  width: 0;
}

.header-only .ant-table-tbody{
  display: none;
}
.flex.space-between {
  justify-content: space-between;
}
.fixed{
  position: fixed;
}

svg {
  width: 100% !important;
}